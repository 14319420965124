import { QuebicOpcodes } from "../../base";
import { Message } from "./message";

/**
 * Represents a heartbeat command message.
 * The data is the current timestamp in Ms.
 *
 * @export
 * @class Heartbeat
 * @extends {Message}
 */
export class Heartbeat extends Message {
	/**
	 * The timestamp sent (or received) from the gateway.
	 *
	 * @type {number}
	 * @memberof Heartbeat
	 */
	public readonly timestamp: number;

	/**
	 * Creates an instance of Heartbeat with the current timestamp.
	 * @memberof Heartbeat
	 */
	constructor() {
		super(QuebicOpcodes.Heartbeat);
		this.timestamp = new Date().getTime();
	}

	public get payload(): string {
		return JSON.stringify({ o: this.opcode, d: this.timestamp });
	}
}