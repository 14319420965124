import axios from "axios";
import { QuebicOAuth2AuthorizeResponse, QuebicOAuth2Provider } from "../../types/oauth";
import { QuebicToken } from "../../types/user";
import { Controller } from "./controller";

export class OAuth extends Controller {
	/**
	 * Create a temporary OAuth2 authorization request.
	 *
	 * @param {string} provider_id
	 * @return {*}  {Promise<QuebicOAuth2AuthorizeResponse>}
	 * @memberof OAuth
	 */
	public async authorize(provider_id: string): Promise<QuebicOAuth2AuthorizeResponse> {
		return await this.fetch(axios.post(`${this.base}/user/oauth2/authorize`, { provider_id }, this.config));
	}

	/**
	 * Login using an OAuth2 grant.
	 * - To login using an Authorizataion Flow specify "response_type": "code", and be sure to specify the "state" parameter.
	 * - To login using an Implicit Flow specify "response_type": "token".
	 *
	 * @param {string} provider_id
	 * @param {("code" | "token")} response_type
	 * @param {string} response_data
	 * @param {string} [state]
	 * @return {*}  {Promise<QuebicToken>}
	 * @memberof OAuth
	 */
	public async login(provider_id: string, response_type: "code" | "token", response_data: string, state?: string): Promise<QuebicToken> {
		return await this.fetch(axios.post(`${this.base}/user/oauth2/login`, { provider_id, response_type, response_data, state }, this.config));
	}

	/**
	 * Links an OAuth2 provider to an existing Quebic Account.
	 * - To link using an Authorizataion Flow specify "response_type": "code", and be sure to specify the "state" parameter.
	 * - To link using an Implicit Flow specify "response_type": "token".
	 *
	 * @param {string} provider_id
	 * @param {("code" | "token")} response_type
	 * @param {string} response_data
	 * @param {string} [state]
	 * @return {*}  {Promise<void>}
	 * @memberof OAuth
	 */
	public async linkAccount(provider_id: string, response_type: "code" | "token", response_data: string, state?: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/user/oauth2/link_account`, { provider_id, response_type, response_data, state }, this.configAuth));
	}

	/**
	 * Unlinks an OAuth2 provider from an existing Quebic Account.
	 *
	 * @param {string} provider_id
	 * @return {*}  {Promise<void>}
	 * @memberof OAuth
	 */
	public async unlinkAccount(provider_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/user/oauth2/unlink_account`, { provider_id }, this.configAuth));
	}

	/**
	 * Adds a supported OAuth2 provider. (Admin use only).
	 *
	 * @param {QuebicOAuth2Provider} provider
	 * @return {*}  {Promise<void>}
	 * @memberof OAuth
	 */
	public async addProvider(provider: QuebicOAuth2Provider): Promise<void> {
		await this.fetch(axios.post(`${this.base}/admin/oauth2/add_provider`, provider, this.configAuth));
	}
}