import { QuebicVoiceState } from "../../types/voice";
import { VoiceStateChange } from "../message/voiceState";
import { Controller } from "./controller";

export class Voice extends Controller {
	/**
	 * Updates a voice state, must be connected to the Quebic Gateway.
	 * Information is returned via the corrosponding push events.
	 * @param state 
	 */
	public async updateState(state: QuebicVoiceState): Promise<void> {
		await this.client.gateway?.send(new VoiceStateChange(state));
	}
}