import { QuebicUserKeys, QuebicUserDerivedKeys } from "../types/userKeys";
import * as ed25519 from "@noble/ed25519";

/**
 * Support crypto on both browser/nodejs environments.
 */
const crypto: Crypto = process.env.IS_BROWSER === "true" ? window.crypto : require("crypto");

/**
 * Number of encryption iterations to use.
 * Must be the same for both encrypt and decrypt.
 */
const iterations = 150000;

/**
 * Generates {@link UserKeys} from the given plaintext password.
 * @param password
 */
export async function generateUserKeys(password: string): Promise<QuebicUserKeys> {
	const masterKey = crypto.getRandomValues(new Uint8Array(32));
	const clientRandom = crypto.getRandomValues(new Uint8Array(16));

	const ed25519PrivateKey = crypto.getRandomValues(new Uint8Array(32));
	const ed24419PublicKey = await ed25519.getPublicKey(ed25519PrivateKey);
	const ed25519IV = crypto.getRandomValues(new Uint8Array(16));

	const algCbc: AesCbcParams = { name: "AES-CBC", iv: ed25519IV };
	const masterKeyCryptoKey = await crypto.subtle.importKey("raw", masterKey, algCbc, false, ["encrypt"]);

	const encryptedEd25519Key = await crypto.subtle.encrypt(algCbc, masterKeyCryptoKey, ed25519PrivateKey);

	const salt = await genSalt(clientRandom);
	const derivedKey = await genDerivedKey(password, salt, iterations);

	const encryptionKeyLength = derivedKey.byteLength / 2;
	const encryptionKey = derivedKey.slice(0, encryptionKeyLength);
	const userKeyData = derivedKey.slice(encryptionKeyLength);

	const userKey = await crypto.subtle.digest("SHA-256", userKeyData);
	const masterKeyTriplet = await genMasterKeyTriplet(masterKey, encryptionKey);

	return {
		client_random: encodeBase64(clientRandom),
		master_key: masterKeyTriplet,
		user_key: encodeBase64(userKey),
		signature_private_key: `${encodeBase64(ed25519IV)}:${encodeBase64(encryptedEd25519Key)}`,
		signature_public_key: encodeBase64(ed24419PublicKey)
	};
}

/**
 * Generates a {@link DerivedKeys} from the provided password and base64 encoded salt.
 * @param password plaintext password
 * @param salt Base64 encoded salt
 */
export async function generateDeriveKeys(password: string, salt: string): Promise<QuebicUserDerivedKeys> {
	const saltData = decodeBase64(salt);
	const derivedKey = await genDerivedKey(password, saltData, iterations);

	const encryptionKeyLength = derivedKey.byteLength / 2;
	const encryptionKey = derivedKey.slice(0, encryptionKeyLength);
	const userKeyData = derivedKey.slice(encryptionKeyLength);

	return {
		encryption_key: encryptionKey,
		user_key: encodeBase64(userKeyData)
	};
}

/**
 * Decrypt a master key from the provided master key triplet.
 * @param triplet Master key triplet
 * @param encryptionKey
 * @returns Decrypted master key
 */
export async function decryptMasterKeyTriplet(triplet: string, encryptionKey: ArrayBuffer): Promise<string> {
	const [ivLenStr, ivBase64, cipherDataBase64] = triplet.split(":");
	const ivLen = parseInt(ivLenStr);
	const iv = decodeBase64(ivBase64);
	const cipherData = decodeBase64(cipherDataBase64);

	if (iv.byteLength !== ivLen) {
		throw TypeError("Invalid triplet");
	}

	const alg: AesGcmParams = { name: "AES-GCM", iv };

	// Import the decryptionKey as a crypto key
	const decryptionCryptoKey = await crypto.subtle.importKey(
		"raw",
		encryptionKey,
		alg,
		false,
		["decrypt"]
	);

	return encodeBase64(await crypto.subtle.decrypt(alg, decryptionCryptoKey, cipherData));
}

/**
 * Generates a client salt from the client random value.
 * @param clientRandom 
 */
export async function generateSalt(clientRandom: string): Promise<string> {
	const salt = await genSalt(new Uint8Array(decodeBase64(clientRandom)));
	return encodeBase64(salt);
}

/**
 * Generates a hashed salt value from the given clientRandom data.
 * @param clientRandom
 */
async function genSalt(clientRandom: Uint8Array): Promise<ArrayBuffer> {
	const saltParamsLength = 250;
	const saltParamsPrefixLength = saltParamsLength - clientRandom.length;

	const text = new TextEncoder().encode("qbic".padEnd(saltParamsPrefixLength, " "));

	const saltParams = concatArrays(text, clientRandom);
	const salt = await crypto.subtle.digest("SHA-256", saltParams);

	return salt;
}

/**
 * Generate a derived key from the given parameters.
 * @param password Plaintext password
 * @param salt Hashed salt value generated by {@link genSalt}
 * @param iterations Number of key iterations to use
 * @param len Output length for the generated key
 */
async function genDerivedKey(
	password: string,
	salt: ArrayBuffer,
	iterations: number,
	len = 64
): Promise<ArrayBuffer> {
	const passwordBuffer = new TextEncoder().encode(password);

	// Import the password as a crypto key
	const baseKey = await crypto.subtle.importKey(
		"raw",
		passwordBuffer,
		"PBKDF2",
		false,
		["deriveBits"]
	);

	// Get the actual derived key
	const derivedKey = await crypto.subtle.deriveBits(
		{
			name: "PBKDF2",
			hash: "SHA-512", // implicitly does HMAC apparently
			iterations,
			salt,
		},
		baseKey,
		len * 8,
	);

	return derivedKey;
}

/**
 * Generate a master key triplet string using AES-GCM.
 * @returns {string} Triplet string `<iv_len>:<iv_base64>:<ciphertext_base64>`
 */
async function genMasterKeyTriplet(masterKey: ArrayBuffer, encryptionKey: ArrayBuffer): Promise<string> {
	const iv = crypto.getRandomValues(new Uint8Array(16));

	// Explicit type isn't really necessary here, but the explicit object is.
	// If this object were defined inline in the `crypto.subtle.importKey` call,
	// there would be a type resolution error.
	const alg: AesGcmParams = { name: "AES-GCM", iv };

	// Import the encryptionKey as a crypto key
	const encryptionCryptoKey = await crypto.subtle.importKey(
		"raw",
		encryptionKey,
		alg,
		false,
		["encrypt"]
	);

	// Generate the master key cipher data
	const cipherData = await crypto.subtle.encrypt(alg, encryptionCryptoKey, masterKey);

	const cipherDataBase64 = encodeBase64(cipherData);
	const ivBase64 = encodeBase64(iv);

	// Return the formatted master key triplet
	return `${iv.length}:${ivBase64}:${cipherDataBase64}`;
}

/**
 * Combines two or more {@link Uint8Array} instances.
 * This method returns a new {@link Uint8Array} without modifying any existing arrays.
 */
function concatArrays(...arrays: Uint8Array[]): Uint8Array {
	// Calculate the total length of all passed arrays
	let length = 0;
	for (const arr of arrays) {
		length += arr.length;
	}

	const mergedArray = new Uint8Array(length);

	let offset = 0;
	for (const array of arrays) {
		mergedArray.set(array, offset);
		offset += array.length;
	}

	return mergedArray;
}

/**
 * Encodes the specified buffer as a string using Base64.
 */
function encodeBase64(buf: ArrayBuffer): string {
	const text = Array.from(new Uint8Array(buf))
		.map(v => String.fromCharCode(v))
		.join("");

	return btoa(text);
}

/**
 * Decodes a string of Base64-encoded data into an {@link ArrayBuffer}
 */
function decodeBase64(text: string): ArrayBuffer {
	return Uint8Array.from(atob(text), c => c.charCodeAt(0));
}
