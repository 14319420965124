import { QuebicRole, QuebicRoleFlag } from "../types/role";

/**
 * Utility for operating with Quebic's 64bit bitwise role flags.
 */
export class QuebicRoleFlags {
	private value: bigint;

	private constructor(value: bigint) {
		this.value = value;
	}

	/**
	 * Checks if the given flag is set.
	 *
	 * @param {QuebicRoleFlag} flag
	 * @return {*}  {boolean}
	 * @memberof QuebicRoleFlags
	 */
	public contains(flag: QuebicRoleFlag): boolean {
		const check = BigInt(1) << BigInt(flag);
		return (this.value & check) === check;
	}

	/**
	 * Enables the given flag.
	 * @param flag 
	 */
	public enable(flag: QuebicRoleFlag): QuebicRoleFlags {
		const value = BigInt(1) << BigInt(flag);
		this.value |= value;
		return this;
	}

	/**
	 * Disables the given flag.
	 * @param flag 
	 */
	public disable(flag: QuebicRoleFlag): QuebicRoleFlags {
		const value = BigInt(1) << BigInt(flag);
		this.value &= ~value;
		return this;
	}

	/**
	 * Disables all flags disabled in the set.
	 * @returns 
	 */
	public not(): QuebicRoleFlags {
		this.value = ~this.value;
		return this;
	}

	/**
	 * Disables all flags disabled in the set.
	 *
	 * @param {QuebicRoleFlags} rhs
	 * @memberof QuebicRoleFlags
	 */
	public andAssign(rhs: QuebicRoleFlags): QuebicRoleFlags {
		this.value &= rhs.value;
		return this;
	}

	/**
	 * Adds the set of flags.
	 *
	 * @param {QuebicRoleFlags} rhs
	 * @memberof QuebicRoleFlags
	 */
	public orAssign(rhs: QuebicRoleFlags): QuebicRoleFlags {
		this.value |= rhs.value;
		return this;
	}

	/**
	 * Converts the role flags into a formatted string with all the set flags.
	 *
	 * @return {*}  {string}
	 * @memberof QuebicRoleFlags
	 */
	public prettyPrint(): string {
		const flags: string[] = [];

		for (const flag in QuebicRoleFlag) {
			if (!isNaN(Number(flag))) {
				// Typescript doesn't like converting values into enum variants.
				if (this.contains(flag as unknown as QuebicRoleFlag)) {
					flags.push(`${QuebicRoleFlag[flag]}`);
				}
			}
		}

		return flags.join(", ");
	}

	/**
	 * Converts this role flags back into it's string representation.
	 * @returns 
	 */
	public toString(): string {
		return this.value.toString(10);
	}

	/**
	 * Constructs a new role flags from the role.
	 *
	 * @static
	 * @param {QuebicRole} [role]
	 * @return {*}  {QuebicRoleFlags}
	 * @memberof QuebicRoleFlags
	 */
	public static fromQuebicRole(role?: QuebicRole): QuebicRoleFlags {
		if (role) {
			return this.fromString(role.flags ?? "0");
		} else {
			return QuebicRoleFlags.empty();
		}
	}

	/**
	 * Constructs a new role flags from the string value.
	 * @param value 
	 * @returns 
	 */
	public static fromString(value?: string): QuebicRoleFlags {
		return new QuebicRoleFlags(BigInt(value ?? 0));
	}

	/**
	 * Constructs a new role flags from the bigint value.
	 * @param value 
	 * @returns 
	 */
	public static fromBigInt(value: bigint): QuebicRoleFlags {
		return new QuebicRoleFlags(value);
	}

	/**
	 * Constructs a new role flags with no flags enabled.
	 * @returns 
	 */
	public static empty(): QuebicRoleFlags {
		// i64 default value.
		return this.fromBigInt(BigInt(0));
	}

	/**
	 * Constructs a new role flags with the default flags enabled.
	 * @returns 
	 */
	public static default(): QuebicRoleFlags {
		return this.empty()
			.enable(QuebicRoleFlag.ViewChannels)
			.enable(QuebicRoleFlag.CreateInvite)
			.enable(QuebicRoleFlag.SendMessages)
			.enable(QuebicRoleFlag.RichEmbeds)
			.enable(QuebicRoleFlag.AttachFiles)
			.enable(QuebicRoleFlag.MentionsRoles)
			.enable(QuebicRoleFlag.MessageHistory)
			.enable(QuebicRoleFlag.VoiceConnect)
			.enable(QuebicRoleFlag.VoiceSpeak)
			.enable(QuebicRoleFlag.VoiceVideo);
	}

	/**
	 * Constructs a new role flags with all flags enabled.
	 * @returns 
	 */
	public static all(): QuebicRoleFlags {
		// i64 max value.
		return this.fromBigInt(BigInt("18446744073709551615"));
	}
}