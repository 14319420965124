/**
 * Utilities for working with snowflake ids.
 *
 * @export
 * @class QuebicSnowflake
 */
export class QuebicSnowflake {
	private static SIZEOF_BIGINT = BigInt(64);
	private static EPOCH_BITS = BigInt(42);
	private static START_EPOCH = BigInt("1577836800000");

	/**
	 * Extracts the timestamp in ms from the snowflake.
	 *
	 * @static
	 * @param {string} value The snowflake to extract the value from.
	 * @return {*}  {number} Timestamp in ms.
	 * @memberof QuebicSnowflake
	 */
	public static extractTimestamp(value: string): number {
		return Number((BigInt(value) >> (this.SIZEOF_BIGINT - this.EPOCH_BITS)) + this.START_EPOCH);
	}
}