import { Client, ClientEvents } from "../client/client";
import { QuebicError } from "../types/error";
import { QuebicSessionInit } from "../types/userLogin";
import { downloadFile } from "../utilities/file";
import { QuebicJsonWebToken } from "../utilities/jwt";
import { Manager } from "./manager";

/**
 * Events that SessionManager emits.
 *
 * @export
 * @enum {number}
 */
export enum SessionManagerEvents {
	/**
	 * The session manager is attempting to restore your session.
	 */
	SessionInitializing = "initializing",
	/**
	 * A valid session was established and client is logged in.
	 */
	SessionEstablished = "established",
	/**
	 * You disconnected and we're trying to get you back online.
	 * This callback has an argument: `nextRetryMs: number` which
	 * is the milliseconds until next connection retry.
	 */
	SessionReconnecting = "reconnecting",
	/**
	 * The session was destroyed for any reason. The user must manually re-authenticate.
	 */
	SessionDestroyed = "destroyed",
}

/**
 * An interface that describes the available session manager events for the emitter.
 */
interface SessionManagerEvent {
	[SessionManagerEvents.SessionInitializing]: () => void,
	[SessionManagerEvents.SessionEstablished]: () => void,
	[SessionManagerEvents.SessionReconnecting]: (nextRetryMs: number) => void,
	[SessionManagerEvents.SessionDestroyed]: () => void,
}

/**
 * Methods to work with storing persistent data in Quebic.
 */
export interface SessionManagerStore {
	/**
	 * Fetches a value from storage with the given key.
	 * @param key 
	 */
	getItem(key: string): Promise<string | null>;
	/**
	 * Saves a key value pair into storage.
	 * @param key 
	 * @param value 
	 */
	setItem(key: string, value: string): Promise<void>;
	/**
	 * Removes an item from storage by it's key.
	 * @param key 
	 */
	removeItem(key: string): Promise<void>;
}

/**
 * Allows consumers of session manager to extend the functionality.
 */
export interface SessionManagerExtensions {
	/**
	 * Overrides the storage mechanism for session manager.
	 */
	store?: SessionManagerStore;
}

/**
 * Responsible for keeping a user session alive, refreshing the API token
 * and storage of longer lived refresh tokens. This manager emits callbacks for
 * the client state that you can use in addition to the client hooks.
 * 
 * By default, SessionManager will remember the current session.
 * You can use setRememberMe() to prevent the session from saving.
 *
 * @export
 * @class SessionManager
 * @extends {Manager}
 */
export class SessionManager extends Manager<SessionManagerEvent> {
	private refreshToken: string | null = null;
	private masterKey: string | null = null;
	private initialStateFound: null | boolean = null;
	private rememberMe = true;

	private clientConnected = false;
	private refreshInterval = 0;

	private config: SessionManagerExtensions;

	constructor(client: Client, config: SessionManagerExtensions = {}) {
		super(client);

		// Configure default options.
		this.config = Object.assign<SessionManagerExtensions, SessionManagerExtensions>({
			store: {
				getItem(key) { return new Promise((resolve) => resolve(window.localStorage.getItem(key))); },
				setItem(key, value) { return new Promise((resolve) => resolve(window.localStorage.setItem(key, value))); },
				removeItem(key) { return new Promise((resolve) => resolve(window.localStorage.removeItem(key))); }
			}
		}, config);


		// Add our client event hooks
		this.client.on(ClientEvents.Disconnected, () => this.onDisconnected());
		this.client.on(ClientEvents.Connected, () => this.onConnected());
		this.client.on(ClientEvents.ReconnectRequired, () => this.onReconnect());
	}

	protected get className(): string {
		return "SessionManager";
	}

	private get isNodeJs(): boolean {
		return typeof window === undefined;
	}

	private get isOnline(): boolean {
		return typeof window !== undefined && window.navigator.onLine;
	}

	private onReconnect(): void {
		// Gateway requested that re reconnect. Try once and on failure normal reconnect.
		if (this.client.sessionToken === null) {
			this.log("onReconnect fired but sessionToken was null.");
			return;
		}

		// Call refresh, disconnect on error
		this.refresh().catch(() => this.onDisconnected());
	}

	private onConnected(): void {
		this.clientConnected = true;

		if (this.client.sessionToken === null) {
			this.log("onConnected fired but sessionToken was null.");
			return;
		}

		this.emit(SessionManagerEvents.SessionEstablished);

		const token = QuebicJsonWebToken.fromString(this.client.sessionToken);

		// Should never happen but a good case to default to.
		if (token.isExpired) {
			this.log("onConnected token was already expired.");
			return;
		}

		// Refresh at about 2/3rds of the expire time left
		const timeUntilExpired = (token.expires.getTime() - new Date().getTime()) * 0.67;
		this.refreshInterval = setTimeout(() => this.refresh(), timeUntilExpired) as unknown as number;
	}

	private onDisconnected(): void {
		const wasConnected = this.clientConnected;
		this.clientConnected = false;

		// Don't bother, could be stale event.
		if (!wasConnected) {
			return;
		}

		let reconnectTimer = 1;
		this.log(`User has disconnected, attempting to reconnect in ${reconnectTimer}s, isOnline: ${this.isOnline}`);

		const callback = async () => {
			// Attempt to reconnect
			try {
				await this.reconnect();
			} catch (e) {
				// Add some fuzzing in case our servers died
				reconnectTimer += 15 + Math.floor(Math.random() * 10);
				this.emit(SessionManagerEvents.SessionReconnecting, reconnectTimer * 1000);

				if (e instanceof QuebicError) {
					// On bad auth values, destroy session w/ notify
					if (e.message in ["unauthorized", "__internal_no_authentication_tokens"]) {
						return this.destroy();
					}
				}

				this.log(`User has disconnected, attempting to reconnect in ${reconnectTimer}s, isOnline: ${this.isOnline}`);
				setTimeout(() => callback(), reconnectTimer * 1000);
			}
		};
		setTimeout(() => callback(), reconnectTimer * 1000);
	}

	private onBeforeUnload(): void {
		// Persist the token to local storage, we'll fetch
		// and delete it on next initialize() call.
		if (this.refreshToken && this.rememberMe) {
			this.config.store?.setItem("token", this.refreshToken);
		}
		if (this.masterKey && this.rememberMe) {
			this.config.store?.setItem("masterKey", this.masterKey);
		}
	}

	private async refresh(): Promise<void> {
		// Refresh the token using the refresh token
		if (!this.refreshToken) {
			this.log("Missing refresh token in refresh tick.");
			return;
		}

		// Try to refresh the session, if failed, we can destroy here.
		let authToken: QuebicSessionInit;
		try {
			authToken = await this.client.user.refresh(this.refreshToken);
		} catch (e) {
			this.log(`Failed to refresh session: ${e}`);
			return this.destroy();
		}

		this.client.setToken(authToken);

		// Decode it so we can fetch the new expire time
		const token = QuebicJsonWebToken.fromString(authToken.token);

		// Refresh at about 2/3rds of the expire time left
		const timeUntilExpired = (token.expires.getTime() - new Date().getTime()) * 0.67;
		this.refreshInterval = setTimeout(() => this.refresh(), timeUntilExpired) as unknown as number;
	}

	private async reconnect(): Promise<void> {
		// We need to reconnect, if we don't have a refresh token, let's see if the client's is valid
		let authToken: string;

		if (this.refreshToken) {
			// Ask the api for a new auth token...
			authToken = (await this.client.user.refresh(this.refreshToken)).token;
		} else if (this.refreshToken == null && this.client.sessionToken != null && QuebicJsonWebToken.fromString(this.client.sessionToken).expires > new Date()) {
			// Our existing token is still valid, use it
			authToken = this.client.sessionToken;
		} else {
			// Can't no authentication method
			throw new QuebicError(-1, "__internal_no_authentication_tokens");
		}

		// Try to reauth with the gateway
		await this.client.connect({ token: authToken, token_type: "auth" });
	}

	/**
	 * Calling this method will determine whether or not
	 * SessionManager will save the session, (default: true)
	 *
	 * @memberof SessionManager
	 */
	public setRememberMe(value: boolean): void {
		this.rememberMe = value;
	}

	/**
	 * Destroys the current session and logs out the client if necessary.
	 *
	 * @private
	 * @return {*}  {Promise<void>}
	 * @memberof SessionManager
	 */
	public destroy(): void {
		clearInterval(this.refreshInterval);
		this.refreshToken = null;

		// Token was invalid, or needs to be removed.
		this.config.store?.removeItem("token");
		this.config.store?.removeItem("masterKey");

		this.client.destroy();
		this.clientConnected = false;

		this.emit(SessionManagerEvents.SessionDestroyed);
	}

	/**
	 * Login the client with the new refresh token.
	 *
	 * @param {QuebicSessionInit} session
	 * @memberof SessionManager
	 */
	public async login(session: QuebicSessionInit): Promise<void> {
		if (session.token_type !== "refr") {
			throw new Error("Invalid token type, expecting refr");
		}
		if (this.clientConnected) {
			throw new Error("The client is already connected");
		}

		this.refreshToken = session.token;

		if (this.rememberMe) {
			this.config.store?.setItem("token", this.refreshToken);
		}

		return await this.reconnect();
	}

	/**
	 * Sets a user's master key for the session.
	 * @param masterKey 
	 */
	public setMasterKey(masterKey: string) {
		this.masterKey = masterKey;

		if (this.rememberMe) {
			this.config.store?.setItem("masterKey", this.masterKey);
		}
	}

	/**
	 * Exports a backup of the master key, used for account recovery.
	 */
	public exportMasterKey(userId: string) {
		if (this.masterKey) {
			downloadFile(`QuebicBackup${userId}.txt`, this.masterKey);
		}
	}

	/**
	 * Waits for the initial client state to load (SessionDestroyed/SessionEstablished)
	 * returns instantly if that has already happened
	 *
	 * @return {*}  {Promise<boolean>} true if the client has an active session, false if the session is invalid or missing.
	 * @memberof SessionManager
	 */
	public waitForInitialState(): Promise<boolean> {
		return new Promise((resolve) => {
			if (this.initialStateFound !== null) {
				resolve(this.clientConnected);
			}

			this.once(SessionManagerEvents.SessionDestroyed, () => resolve(this.clientConnected));
			this.once(SessionManagerEvents.SessionEstablished, () => resolve(this.clientConnected));
		});
	}

	/**
	 * Initializes the session and attempts to find an existing session store.
	 * (You should listen for events to determine what to do next.)
	 *
	 * @return {*}  {Promise<void>}
	 * @memberof SessionManager
	 */
	public async initialize(): Promise<void> {
		// Let users know we're trying to get one
		this.emit(SessionManagerEvents.SessionInitializing);
		// Our initialization routine only applies to browser based storage.
		if (this.isNodeJs) {
			this.initialStateFound = false;
			this.emit(SessionManagerEvents.SessionDestroyed);
			return;
		}

		// We'll save the latest token before we end the session.
		window.addEventListener("beforeunload", () => this.onBeforeUnload());
		window.addEventListener("pagehide", () => this.onBeforeUnload());
		// Check for the presence of a refresh token in local storage.
		this.refreshToken = await this.config.store?.getItem("token") ?? null;
		this.masterKey = await this.config.store?.getItem("masterKey") ?? null;

		this.log(`Initialized session manager, isBrowser: ${!this.isNodeJs}, foundToken: ${this.refreshToken !== null}`);

		if (!this.refreshToken) {
			this.log("Session token was not found");
			this.initialStateFound = false;
			this.emit(SessionManagerEvents.SessionDestroyed);
			return;
		}

		// Actually connect using the token
		try {
			await this.reconnect();
		} catch (e) {
			this.destroy();
		}

		this.initialStateFound = false;
	}
}