import axios from "axios";
import { QuebicRole, QuebicRoleCreate, QuebicRoleUpdate } from "../../types/role";
import { Controller } from "./controller";

export class Role extends Controller {
	protected get base(): string {
		return `${super.base}/space`;
	}

	/**
	 * Creates a new role in the given space.
	 * - You must have the `ManageRoles` flag in order to call this API.
	 *
	 * @param {string} space_id
	 * @param {QuebicRoleCreate} role
	 * @return {*}  {Promise<QuebicRole>}
	 * @memberof Role
	 */
	public async create(space_id: string, role: QuebicRoleCreate): Promise<QuebicRole> {
		return await this.fetch(axios.post(`${this.base}/${space_id}/roles`, role, this.configAuth));
	}

	/**
	 * Updates an existing role in the given space.
	 * - You must have the `ManageRoles` flag in order to call this API, and have a role assigned to you that is > the position.
	 *
	 * @param {string} space_id
	 * @param {string} role_id
	 * @param {QuebicRoleUpdate} updates
	 * @return {*}  {Promise<QuebicRole>}
	 * @memberof Role
	 */
	public async update(space_id: string, role_id: string, updates: QuebicRoleUpdate): Promise<QuebicRole> {
		return await this.fetch(axios.patch(`${this.base}/${space_id}/roles/${role_id}`, updates, this.configAuth));
	}

	/**
	 * Deletes a role from the given space.
	 * - You must have the `ManageRoles` flag in order to call this API.
	 * - You can only delete a role that's `position` is less than your highest assigned role `position`.
	 *
	 * @param {string} space_id
	 * @param {string} role_id
	 * @return {*}  {Promise<void>}
	 * @memberof Role
	 */
	public async delete(space_id: string, role_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${space_id}/roles/${role_id}`, this.configAuth));
	}
}