import { QuebicSpaceMember } from "./space";

/**
 * Defines the type of member list operation.
 *
 * @export
 * @enum {number}
 */
export enum QuebicMemberListUpdateOpcode {
	/**
	 * This operation contains all of the `QuebicSpaceMember`'s for the given range.
	 */
	Sync = 0,
	/**
	 * This operation should insert a `QuebicSpaceMember` at the given index.
	 */
	Insert = 1,
	/**
	 * This operation updates a `QuebicSpaceMember` at the given index and may contain partial information.
	 */
	Update = 2,
	/**
	 * This operation deletes a `QuebicSpaceMember` at the given index and only contains basic user information.
	 */
	Delete = 3,
}

/**
 * An operation that defines how to modify a virtual member list.
 *
 * @export
 * @interface QuebicMemberListUpdateOp
 */
export interface QuebicMemberListUpdateOp {
	/**
	 * Determines how to process this operation.
	 *
	 * @type {QuebicMemberListUpdateOpcode}
	 * @memberof QuebicMemberListUpdateOp
	 */
	opcode: QuebicMemberListUpdateOpcode,

	/**
	 * An index into the virtual list, used for `Insert`, `Update`, and `Delete` opcodes.
	 *
	 * @type {number}
	 * @memberof QuebicMemberListUpdateOp
	 */
	index?: number,
	/**
	 * A `QuebicSpaceMember`, used for `Insert`, `Update`, and `Delete` opcodes.
	 *
	 * @type {QuebicSpaceMember}
	 * @memberof QuebicMemberListUpdateOp
	 */
	item?: QuebicSpaceMember,

	/**
	 * Start and end ranges that are currently subscribed to for the `Sync` opcode.
	 *
	 * @type {number[]}
	 * @memberof QuebicMemberListUpdateOp
	 */
	range?: number[],
	/**
	 * A list of `QuebicSpaceMember`'s used in a `Sync` opcode operation.
	 *
	 * @type {QuebicSpaceMember[]}
	 * @memberof QuebicMemberListUpdateOp
	 */
	items?: QuebicSpaceMember[],
}