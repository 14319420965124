import { QuebicSpaceMember } from "./space";

/**
 * Represents a user session token.
 *
 * @export
 * @interface QuebicToken
 */
export interface QuebicToken {
	token: string;
	token_type: "auth" | "otp" | "refr" | "sso";
}

/**
 * Whether or not a username is in use already.
 *
 * @export
 * @interface QuebicUsername
 */
export interface QuebicUsername {
	/**
	 * When `true` the username is already claimed by another member.
	 *
	 * @type {boolean}
	 * @memberof QuebicUsername
	 */
	in_use: boolean;
}

/**
 * Represents a Quebic user account.
 *
 * @export
 * @interface QuebicUser
 */
export interface QuebicUser {
	/**
	 * The user id. This is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicUser
	 */
	id: string;
	/**
	 * The users global access role.
	 *
	 * @type {number}
	 * @memberof QuebicUser
	 */
	access_role?: number;
	/**
	 * Whether or not the account has a verified email.
	 *
	 * @type {boolean}
	 * @memberof QuebicUser
	 */
	activated?: boolean;
	/**
	 * The nick name for this user. Takes precident over `username`.
	 *
	 * @type {string}
	 * @memberof QuebicUser
	 */
	nick?: string;
	/**
	 * The avatar image id. This is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicUser
	 */
	avatar?: string;
	/**
	 * The banner image id. this is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicUser
	 */
	banner?: string;
	/**
	 * The unique username for this user.
	 *
	 * @type {string}
	 * @memberof QuebicUser
	 */
	username?: string;
	/**
	 * The date this user registered for an account.
	 *
	 * @type {Date}
	 * @memberof QuebicUser
	 */
	created_at?: Date;
}

/**
 * An event that occurs when the user indicated they are typing a message.
 *
 * @export
 * @interface QuebicUserTyping
 */
export interface QuebicUserTyping {
	/**
	 * The channel id of the typing event.
	 *
	 * @type {string}
	 * @memberof QuebucUserTyping
	 */
	channel_id: string;
	/**
	 * The space id if any of the space this belongs to.
	 *
	 * @type {string}
	 * @memberof QuebucUserTyping
	 */
	space_id?: string;
	/**
	 * The id of the user who is typing.
	 *
	 * @type {string}
	 * @memberof QuebucUserTyping
	 */
	user_id: string;
	/**
	 * Unix timestamp in seconds of when the user started typing.
	 * (There is no stop event, this should be handled by the client)
	 *
	 * @type {number}
	 * @memberof QuebucUserTyping
	 */
	timestamp: number;
	/**
	 * If the channel belongs to a space, this field will be sent to indicate the user.
	 * (Otherwise, you should have them cached and use the user_id)
	 *
	 * @type {QuebicSpaceMember}
	 * @memberof QuebucUserTyping
	 */
	member?: QuebicSpaceMember;
}

/**
 * Represents the current status of the user.
 *
 * @export
 * @enum {number}
 */
export enum QuebicUserStatus {
	/**
	 * Offline
	 */
	Offline = 0,
	/**
	 * Appearing offline
	 */
	Invisible = 1,
	/**
	 * Online
	 */
	Online = 2,
	/**
	 * Away from keyboard
	 */
	Afk = 3,
	/**
	 * Do not disturb
	 */
	Dnd = 4,
}

/**
 * Represents the current user's status online and what they may be doing.
 *
 * @export
 * @interface QuebicUserPresence
 */
export interface QuebicUserPresence {
	/**
	 * The users current status.
	 *
	 * @type {QuebicUserStatus}
	 * @memberof QuebicUserPresence
	 */
	status: QuebicUserStatus,
	/**
	 * What the user is currently doing when available.
	 *
	 * @type {unknown}
	 * @memberof QuebicUserPresence
	 */
	activity?: unknown,
}