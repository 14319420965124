import { Json2 } from "../client/internal/json";

// Internal use here but should always match
interface QuebicPayload {
	exp: number;
	iat: number;
	aud: string;
	user?: { id: string; access_role: number; }
}

/**
 * Information about a Quebic session token.
 *
 * @export
 * @class QuebicJsonWebToken
 */
export class QuebicJsonWebToken {
	public readonly expires: Date;
	public readonly issued: Date;
	public readonly tokenType: boolean;
	public readonly userId: string;
	public readonly accessRole: number;

	/**
	 * Whether or not this token is expired.
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof QuebicJsonWebToken
	 */
	public get isExpired(): boolean {
		return this.expires < new Date();
	}

	/**
	 * Whether or not this token is activated (Isssued and not expired).
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof QuebicJsonWebToken
	 */
	public get isActive(): boolean {
		return this.issued < new Date() && !this.isExpired;
	}

	protected constructor(data: { expires: Date; issued: Date; tokenType: string; userId: string; accessRole: number; }) {
		Object.assign(this, data);
	}

	private static decodeBase64(value: string): string {
		if (typeof atob === "function") {
			return atob(value);
		} else {
			return Buffer.from(value, "base64").toString("utf-8");
		}
	}

	/**
	 * Extracts information from a Quebic session token.
	 *
	 * @static
	 * @param {string} value
	 * @return {*}  {QuebicJsonWebToken}
	 * @memberof QuebicJsonWebToken
	 */
	public static fromString(value: string): QuebicJsonWebToken {
		const parts = value.split(".");

		if (parts.length !== 3) {
			throw new Error("The token provided was invalid: missing part(s)");
		}

		try {
			const header: { alg: string; } = Json2.parse(this.decodeBase64(parts[0]));
			const payload: QuebicPayload = Json2.parse(this.decodeBase64(parts[1]));

			if (header.alg !== "HS256") {
				throw new Error("invalid algorithm found");
			}

			return new QuebicJsonWebToken({
				expires: new Date(payload.exp * 1000),
				issued: new Date(payload.iat * 1000),
				tokenType: payload.aud,
				userId: payload.user?.id || "0",
				accessRole: payload.user?.access_role || 0,
			});
		} catch (e) {
			console.log(e);
			throw new Error("The token provided was invalid: failed to decode part(s)");
		}
	}
}