import { QuebicOpcodes } from "../../base";

/**
 * Base class for command messages.
 *
 * @export
 * @abstract
 * @class Message
 */
export abstract class Message {
	protected opcode: QuebicOpcodes;

	protected constructor(opcode: QuebicOpcodes) {
		this.opcode = opcode;
	}

	public abstract get payload(): string;
}