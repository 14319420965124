import axios from "axios";
import { QuebicToken } from "../../types/user";
import { Controller } from "./controller";

export class Sso extends Controller {
	protected get base(): string {
		return `${super.base}/sso`;
	}

	/**
	 * Generates a Zendesk login token.
	 *
	 * @return {*}  {Promise<QuebicToken>}
	 * @memberof Sso
	 */
	public async loginZendesk(): Promise<QuebicToken> {
		return await this.fetch(axios.get(`${this.base}/zendesk`, this.configAuth));
	}
}