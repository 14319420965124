export enum QuebicApiVersion {
	V1 = "/api/v1"
}

export enum QuebicOpcodes {
	Hello = 0,
	PushEvent,
	Heartbeat,
	Reconnect,
	MemberList,
	SearchMemberList,
	Ready,
	ReadySupplemental,
	VoiceState,
	Count
}

export enum QuebicWsErrorCodes {
	CloseOnUpgrade = 1006,
}