import { EventEmitter } from "events";
import { TypedEventEmitter } from "../types/typedEmitter";
import { Client } from "../client/client";

export abstract class Manager<Events> extends (EventEmitter as { new <Events>(): TypedEventEmitter<Events> })<Events> {
	protected client: Client;

	constructor(client: Client) {
		super();
		this.client = client;
	}

	protected abstract get className(): string;

	// Logs to the console with cool colors
	protected log(message: unknown, ...args: any[]): void {
		if (window) {
			console.log(`%c[${this.className}]%c ${message}`, "color:#61B69B;", "color:inherit", ...args);
		} else {
			console.log(`[${this.className}] ${message}`, ...args);
		}
	}
}