import { QuebicOpcodes } from "../../base";
import { Message } from "./message";

/**
 * Represents a member list command message.
 * The data is the space_id and a range to subscribe to.
 *
 * @export
 * @class MemberList
 * @extends {Message}
 */
export class MemberList extends Message {
	/**
	 * The space_id to subscribe to.
	 *
	 * @type {string}
	 * @memberof MemberList
	 */
	public readonly space_id: string;
	/**
	 * The start range to subscribe to.
	 *
	 * @type {number}
	 * @memberof MemberList
	 */
	public readonly start: number;
	/**
	 * The end range to subscribe to.
	 *
	 * @type {number}
	 * @memberof MemberList
	 */
	public readonly end: number;
	/**
	 * Creates an instance of MemberList.
	 * @param {string} space_id
	 * @param {number} start
	 * @param {number} end
	 * @memberof MemberList
	 */
	constructor(space_id: string, start: number, end: number) {
		super(QuebicOpcodes.MemberList);
		this.space_id = space_id;
		this.start = start;
		this.end = end;
	}

	public get payload(): string {
		return JSON.stringify({
			o: this.opcode, d: {
				space_id: this.space_id,
				start: this.start,
				end: this.end
			}
		});
	}
}