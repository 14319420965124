/**
 * This represents an error thrown by the Quebic client.
 *
 * @export
 * @class QuebicError
 * @extends {Error}
 */
export class QuebicError extends Error {
	private httpCode: number;

	constructor(code: number, message?: string) {
		super(message ?? "unknown");
		this.httpCode = code;
	}

	public get code(): number {
		return this.httpCode;
	}
}