import axios from "axios";
import { QuebicChannel, QuebicChannelCreate } from "../../types/channel";
import { QuebicSpace, QuebicSpaceCreate } from "../../types/space";
import { fileFormData } from "../internal/formData";
import { MemberList } from "../message/memberList";
import { SearchMemberList } from "../message/searchMemberList";
import { Controller } from "./controller";

export class Space extends Controller {
	protected get base(): string {
		return `${super.base}/space`;
	}

	/**
	 * Create a new space with the specified details.
	 *
	 * @param {QuebicSpaceCreate} space
	 * @return {*}  {Promise<QuebicSpace>}
	 * @memberof Space
	 */
	public async create(space: QuebicSpaceCreate): Promise<QuebicSpace> {
		return await this.fetch(axios.post(this.base, space, this.configAuth));
	}

	/**
	 * List the spaces you are currently a member of.
	 *
	 * @return {*}  {Promise<QuebicSpace[]>}
	 * @memberof Space
	 */
	public async list(): Promise<QuebicSpace[]> {
		return await this.fetch(axios.get(this.base, this.configAuth));
	}

	/**
	 * Delete a space that you are the owner of.
	 * (You obviously can't undo this action)
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<void>}
	 * @memberof Space
	 */
	public async delete(space_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${space_id}`, this.configAuth));
	}

	/**
	 * Get a space by provided id.
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<QuebicSpace>}
	 * @memberof Space
	 */
	public async get(space_id: string): Promise<QuebicSpace> {
		return await this.fetch(axios.get(`${this.base}/${space_id}`, this.configAuth));
	}

	/**
	 * List the channels in the given space.
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<QuebicChannel[]>}
	 * @memberof Space
	 */
	public async channels(space_id: string): Promise<QuebicChannel[]> {
		return await this.fetch(axios.get(`${this.base}/${space_id}/channels`, this.configAuth));
	}

	/**
	 * Create a new channel in the given space.
	 *
	 * @param {string} space_id
	 * @param {QuebicChannelCreate} channel
	 * @return {*}  {Promise<QuebicChannel>}
	 * @memberof Space
	 */
	public async createChannel(space_id: string, channel: QuebicChannelCreate): Promise<QuebicChannel> {
		return await this.fetch(axios.post(`${this.base}/${space_id}/channels`, channel, this.configAuth));
	}

	/**
	 * Leave a space and all channels.
	 * (This will throw an error if you are the owner of the space)
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<void>}
	 * @memberof Space
	 */
	public async leave(space_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/${space_id}/leave`, {}, this.configAuth));
	}

	/**
	 * Request the member list for a given space via it's space_id.
	 * You must provide the currently visible range, usually in 100 increments:
	 * start: 0, end: 99 -> 100 elements. The Gateway will send ClientEvents.MemberListUpdate events with the required after.
	 *
	 * @param {string} space_id
	 * @param {number} start
	 * @param {number} end
	 * @return {*}  {Promise<void>}
	 * @memberof Space
	 */
	public async requestMemberList(space_id: string, start: number, end: number): Promise<void> {
		await this.client.gateway?.send(new MemberList(space_id, start, end));
	}

	/**
	 * Request a search to the member list. The Gateway will send ClientEvents.SearchMemberList events
	 * with the results after.
	 *
	 * @param {string} space_id
	 * @param {string} search_text
	 * @return {*}  {Promise<void>}
	 * @memberof Space
	 */
	public async searchMemberList(space_id: string, search_text: string): Promise<void> {
		await this.client.gateway?.send(new SearchMemberList(space_id, search_text));
	}

	/**
	 * Uploads an icon for a space.
	 *
	 * @param {(string | Blob)} icon
	 * @param {string} space_id
	 * @param {(event: ProgressEvent) => void} [onprogress]
	 * @return {*}  {Promise<QuebicSpace>}
	 * @memberof Space
	 */
	public async uploadIcon(icon: string | Blob, space_id: string, onprogress?: (event: ProgressEvent) => void): Promise<QuebicSpace> {
		const data = fileFormData(icon);
		if (process.env.IS_WEBPACK) {
			return await this.fetch(axios.post(`${this.base}/${space_id}/icon`, data, { ...this.configAuth, onUploadProgress: onprogress }));
		} else {
			return await this.fetch(axios.post(`${this.base}/${space_id}/icon`, data.getBuffer(), { ...this.configAuth, onUploadProgress: onprogress, headers: { ...this.configAuth.headers, ...data.getHeaders() } }));
		}
	}

	/**
	 * Delete an existing space icon image.
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<void>}
	 * @memberof Space
	 */
	public async deleteIcon(space_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${space_id}/icon`, this.configAuth));
	}
}