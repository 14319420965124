import type { QuebicChannel } from "../types/channel";
import { QuebicRole, QuebicRoleFlag } from "../types/role";
import { type QuebicSpace, type QuebicSpaceMember, QuebicSpaceFlags } from "../types/space";
import { QuebicRoleFlags } from "./roles";

/**
 * Returns `true` if the space has all of the given flags set.
 * @param space 
 * @param flags 
 */
export function hasFlag(space: QuebicSpace, ...flags: QuebicSpaceFlags[]): boolean;

export function hasFlag(object: QuebicSpace, ...flags: QuebicSpaceFlags[]): boolean {
	if (object.flags === undefined) {
		return false;
	}

	let value = BigInt(0);

	for (const flag of flags) {
		value |= BigInt(1) << BigInt(flag);
	}

	return (BigInt(object.flags) & value) === value;
}

/**
 * Unpacks a packed color value with 3 components, Red, Green, Blue.
 *
 * @export
 * @param {number} color
 * @return {*}  {[number, number, number]}
 */
export function unpackColor(color?: number): [number, number, number] {
	if (!color) {
		return [255, 255, 255];
	}

	const r = (color >> 0) & 0xFF;
	const g = (color >> 8) & 0xFF;
	const b = (color >> 16) & 0xFF;

	return [r, g, b];
}

/**
 * Packs a color into a single integer with 3 components, Red, Green, Blue.
 *
 * @export
 * @param {number} r 0-255
 * @param {number} g 0-255
 * @param {number} b 0-255
 * @return {*}  {number}
 */
export function packColor(r: number, g: number, b: number): number {
	// No alpha component, just apply 0.
	return ((r & 0xFF) << 0) | ((g & 0xFF) << 8) | ((b & 0xFF) << 16);
}

/**
 * Calculates permission flags in the given space, for the given member.
 * @param space 
 * @param member 
 * @returns 
 */
export function calculateSpaceFlags(space?: QuebicSpace, member?: QuebicSpaceMember): QuebicRoleFlags {
	// Easy workaround if we don't have the info.
	if (!space || !member) {
		return QuebicRoleFlags.empty();
	}

	if (space.owner_id === member.user.id) {
		return QuebicRoleFlags.all();
	}

	const flags = QuebicRoleFlags.fromQuebicRole(space.roles?.find((x) => x.id === space.id));

	for (const role of member.roles || []) {
		flags.orAssign(QuebicRoleFlags.fromQuebicRole(space.roles?.find((x) => x.id === role)));
	}

	if (flags.contains(QuebicRoleFlag.Administrator)) {
		return QuebicRoleFlags.all();
	}

	return flags;
}

function calculateOverrides(base: QuebicRoleFlags, space: QuebicSpace, channel: QuebicChannel, member?: QuebicSpaceMember): QuebicRoleFlags {
	let flags;

	if (channel.is_private) {
		flags = QuebicRoleFlags
			.default()
			.disable(QuebicRoleFlag.CreateInvite);

		if (channel.owner_id === member?.user.id) {
			flags
				.enable(QuebicRoleFlag.ManageChannels)
				.enable(QuebicRoleFlag.CreateInvite);
		}
	} else if (base.contains(QuebicRoleFlag.Administrator)) {
		return QuebicRoleFlags.all();
	} else {
		flags = base;
	}

	if (!channel.role_overrides) {
		return flags;
	}

	const everyoneOverride = channel.role_overrides.find((x) => x.id === space.id);

	if (everyoneOverride) {
		flags.andAssign(QuebicRoleFlags.fromString(everyoneOverride.deny).not());
		flags.orAssign(QuebicRoleFlags.fromString(everyoneOverride.allow));
	}

	for (const role in member?.roles || []) {
		const roleOverride = channel.role_overrides.find((x) => x.id === role);

		if (roleOverride) {
			flags.andAssign(QuebicRoleFlags.fromString(roleOverride.deny).not());
			flags.orAssign(QuebicRoleFlags.fromString(roleOverride.allow));
		}
	}

	const userOverride = channel.role_overrides.find((x) => x.id === member?.user.id);

	if (userOverride) {
		flags.andAssign(QuebicRoleFlags.fromString(userOverride.deny).not());
		flags.orAssign(QuebicRoleFlags.fromString(userOverride.allow));
	}

	return flags;
}

/**
 * Calculates permission flags, in the given space and channel, for the given member.
 * @param space 
 * @param member 
 * @param channel 
 * @returns 
 */
export function calculateChannelFlags(space?: QuebicSpace, channel?: QuebicChannel, member?: QuebicSpaceMember): QuebicRoleFlags {
	// Easy workaround so that we can breakout if the user doesn't have the information.
	if (!space || !channel || !member) {
		return QuebicRoleFlags.empty();
	}

	const base = calculateSpaceFlags(space, member);
	return calculateOverrides(base, space, channel, member);
}

/**
 * Calculates the maximum role assigned to the member based on it's position.
 * @param space 
 * @param member 
 * @returns 
 */
export function calculateMaximumRole(space?: QuebicSpace, member?: QuebicSpaceMember): QuebicRole | undefined {
	if (!space || !member) {
		return undefined;
	}

	let position = 0;

	for (const roleId of member.roles || []) {
		const role = space.roles?.find((x) => x.id === roleId);

		if (role?.position && role.position > position) {
			position = role.position;
		}
	}

	return space.roles?.find((x) => x.position === position);
}