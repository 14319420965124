import FormData from "form-data";
import { QuebicFile } from "../../types/file";

/**
 * Helper routine to encode a file as 'form data'.
 *
 * @export
 * @param {(string | Blob | Buffer)} file
 * @return {*}  {FormData}
 */
export function fileFormData(file: string | Blob | Buffer): FormData {
	const result = new FormData();
	// We'll use this name on the server for general file uploads.
	result.append("file", file);
	return result;
}

/**
 * Helper routine to encode files and a payload as 'form data'.
 *
 * @export
 * @param {(QuebicFile[])} files
 * @param {unknown} payload
 * @return {*}  {FormData}
 */
export function filesAndPayloadFormData(files: QuebicFile[], payload: unknown): FormData {
	const result = new FormData();

	// Maintain order of files and their indexes.
	for (let i = 0; i < files.length; i++) {
		result.append(`file[${i}]`, files[i].file, { contentType: files[i].content_type, filename: files[i].file_name });
	}

	result.append("json_payload", JSON.stringify(payload));
	return result;
}