import axios from "axios";
import { QuebicChannel, QuebicChannelUpdate } from "../../types/channel";
import { Controller } from "./controller";
import { User } from "./user";

export class Channel extends Controller {
	protected get base(): string {
		return `${super.base}/channel`;
	}

	/**
	 * Delete a channel from existance.
	 * (You definitely can't undo this action)
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<void>}
	 * @memberof Channel
	 */
	public async delete(channel_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${channel_id}`, this.configAuth));
	}

	/**
	 * Get a channel by provided id.
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<QuebicChannel>}
	 * @memberof Channel
	 */
	public async get(channel_id: string): Promise<QuebicChannel> {
		return await this.fetch(axios.get(`${this.base}/${channel_id}`, this.configAuth));
	}

	/**
	 * Update one or more attributes of a channel.
	 *
	 * @param {string} channel_id
	 * @param {QuebicChannelUpdate} attributes
	 * @return {*}  {Promise<void>}
	 * @memberof Channel
	 */
	public async update(channel_id: string, attributes: QuebicChannelUpdate): Promise<void> {
		await this.fetch(axios.patch(`${this.base}/${channel_id}`, attributes, this.configAuth));
	}

	/**
	 * Join this public channel to read and see it's messages.
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<void>}
	 * @memberof Channel
	 */
	public async join(channel_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/${channel_id}/join`, {}, this.configAuth));
	}

	/**
	 * Leave this channel, you won't receive messages from it anymore.
	 * (If you own this channel, you must transfer ownership first)
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<void>}
	 * @memberof Channel
	 */
	public async leave(channel_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/${channel_id}/leave`, {}, this.configAuth));
	}

	/**
	 * Indicate to this channel that you are currently typing a message.
	 * (This has no effect if you are currently appearing offline)
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<void>}
	 * @memberof Channel
	 */
	public async typing(channel_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/${channel_id}/typing`, {}, this.configAuth));
	}

	/**
	 * Gets a list of members in the given channel.
	 * (This method will only work for spaces without the `QuebicSpaceFlags.Large` flag)
	 * @param channel_id 
	 * @returns 
	 */
	public async members(channel_id: string): Promise<User[]> {
		return await this.fetch(axios.get(`${this.base}/${channel_id}/members`, this.configAuth));
	}
}