import axios from "axios";
import { QuebicRoleOverrideCreate } from "../../types/role";
import { Controller } from "./controller";

export class RoleOverride extends Controller {
	protected get base(): string {
		return `${super.base}/channel`;
	}

	/**
	 * Creates a new role override on a channel in a space.
	 * - You must have the `ManageRoles` flag in order to call this API.
	 *
	 * @param {string} channel_id
	 * @param {string} id
	 * @param {QuebicRoleOverrideCreate} role_override
	 * @return {*}  {Promise<void>}
	 * @memberof RoleOverride
	 */
	public async create(channel_id: string, id: string, role_override: QuebicRoleOverrideCreate): Promise<void> {
		await this.fetch(axios.put(`${this.base}/${channel_id}/role_overrides/${id}`, role_override, this.configAuth));
	}

	/**
	 * Deletes a role override on a channel in a space.
	 * - You must have the `ManageRoles` flag in order to call this API.
	 *
	 * @param {string} channel_id
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof RoleOverride
	 */
	public async delete(channel_id: string, id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${channel_id}/role_overrides/${id}`, this.configAuth));
	}
}