/**
 * An access control list that belongs to a space, and is assigned to users.
 *
 * @export
 * @interface QuebicRole
 */
export interface QuebicRole {
	/**
	 * Unique id of this role, may be the space's id for @everyone default role.
	 */
	id: string;
	/**
	 * The name of the role.
	 */
	name?: string;
	/**
	 * Position in list  of roles.
	 */
	position?: number;
	/**
	 * Whether or not this role can be mentioned in chat.
	 */
	mentionable?: boolean;
	/**
	 * 64bit bitwise flags/permissions for this role.
	 */
	flags?: string;
	/**
	 * Role color, applied to user's name in chat and member list. Use `unpackColor(color)`.
	 */
	color?: number;
	/**
	 * Role color stop, used as the end point for gradient role colors. Use `unpackColor(color)`.
	 */
	color_stop?: number | null;
	/**
	 * Whether or not the role appears separate in the member list.
	 */
	separated?: boolean;
}

/**
 * Fields required in order to create a new role.
 *
 * @export
 * @interface QuebicRoleCreate
 */
export interface QuebicRoleCreate {
	/**
	 * The name of the new role.
	 */
	name: string;
}

export interface QuebicRoleUpdate {
	/**
	 * Updates the name of the role.
	 */
	name?: string;
	/**
	 * Updates the position of the role.
	 */
	position?: number;
	/**
	 * Updates whether or not the role can be mentioned.
	 */
	mentionable?: boolean;
	/**
	 * Upates the flags that this role provides.
	 */
	flags?: string;
	/**
	 * Updates the color of this role. Use `packColor(R, G, B)`.
	 */
	color?: number;
	/**
	 * Updates the color stop of this role. Use `packColor(R, G, B)`.
	 */
	color_stop?: number | null;
	/**
	 * Whether or not to separate these users in the member lists.
	 */
	separated?: boolean;
}

/**
 * Represents the type of role override.
 *
 * @export
 * @enum {number}
 */
export enum QuebicRoleOverrideType {
	/**
	 * An override who's id corrosponds to a space (@everyone).
	 */
	Space = 0x0,
	/**
	 * An override who's id corrosponds to a role.
	 */
	Role = 0x1,
	/**
	 * An override who's id corrosponds to a member.
	 */
	User = 0x2
}

/**
 * A set of access control overrides for a 'role'/user.
 *
 * @export
 * @interface QuebicRoleOverride
 */
export interface QuebicRoleOverride {
	/**
	 * Unique id of this override, one of: space id, user id, role id.
	 */
	id: string;
	/**
	 * The type of override.
	 */
	type?: QuebicRoleOverrideType;
	/**
	 * 64bit bitwise flags/permissions added to a list.
	 */
	allow?: string;
	/**
	 * 64bit bitwise flags/permissions removed from a list.
	 */
	deny?: string;
}

/**
 * The fields required to create a role override.
 *
 * @export
 * @interface QuebicRoleOverrideCreate
 */
export interface QuebicRoleOverrideCreate {
	/**
	 * The type of override.
	 */
	type: QuebicRoleOverrideType;
	/**
	 * 64bit bitwise flags/permissions added to a list.
	 */
	allow?: string;
	/**
	 * 64bit bitwise flags/permissions removed from a list.
	 */
	deny?: string;
}

/**
 * A list of flags assignable to roles.
 *
 * @export
 * @enum {number}
 */
export enum QuebicRoleFlag {
	/**
	 * Grants all available permissions, with the exception of being able
	 * to view private channels that are invite-only, and not assigned to a role.
	 */
	Administrator = 0,
	/**
	 * Allows the ability to view, join, and leave all public channels.
	 */
	ViewChannels = 1,
	/**
	 * Allows the ability to create, edit, or delete channels.
	 */
	ManageChannels = 2,
	/**
	 * Allows the ability to create, edit, and delete roles lower
	 * than the highest role currently assigned to a user.
	 */
	ManageRoles = 3,
	/**
	 * Gives the ability to add or remove custom emojis.
	 */
	ManageEmojis = 4,
	/**
	 * Reserved.
	 */
	ReservedOne = 5,
	/**
	 * Gives the ability to create, edit, or delete webhooks.
	 */
	ManageWebhooks = 6,
	/**
	 * Gives the ability to manage a spaces:
	 * - Name.
	 * - Description.
	 * - View all invites.
	 * - Add / remove integrations.
	 */
	ManageSpace = 7,
	/**
	 * Allows members to invite new members to this server.
	 */
	CreateInvite = 8,
	/**
	 * Allows the ability to kick users with a lower role.
	 */
	KickMembers = 9,
	/**
	 * Allows the ability to ban users with a lower role.
	 */
	BanMembers = 10,
	/**
	 * Allows the ability to timeout users with a lower role.
	 * - Can't send messages.
	 * - Can't add reactions.
	 * - Can't speak / enable video in voice.
	 */
	TimeoutMembers = 11,
	/**
	 * Allows the ability to send messages in channels.
	 */
	SendMessages = 12,
	/**
	 * Allows the ability to send messages with rich embedded content.
	 */
	RichEmbeds = 13,
	/**
	 * Allows the ability to attach files to messages.
	 */
	AttachFiles = 14,
	/**
	 * Allows the ability to add new reactions to messages.
	 */
	AddReactions = 15,
	/**
	 * Allows the ability to mention:
	 * - @everyone
	 * - @here
	 * - @role_name
	 */
	MentionsRoles = 16,
	/**
	 * Allows the ability to delete, or manage pins for messages created by other users.
	 */
	ManageMessages = 17,
	/**
	 * Grants the ability to see previous messages sent in a channel.
	 */
	MessageHistory = 18,
	/**
	 * Grants the ability to connect to voice and video calls (channels).
	 */
	VoiceConnect = 19,
	/**
	 * Grants the ability to use audio in voice calls.
	 */
	VoiceSpeak = 20,
	/**
	 * Grants the ability to use video/screenshare in voice calls.
	 */
	VoiceVideo = 21,
	/**
	 * Grants the ability to mute members with a lower role.
	 */
	VoiceMuteMembers = 22,
	/**
	 * Grants the ability to deafen members with a lower role.
	 */
	VoiceDeafenMembers = 23,
	/**
	 * Grants the ability to move members to other voice calls with a lower role.
	 */
	VoiceMoveMembers = 24,
	/**
	 * Allows the ability to create private, invite-only channels in a space.
	 */
	CreatePrivateChannels = 25,
}