import { QuebicMemberListUpdateOp } from "../../types/memberList";
import { Client } from "../client";
import { GatewayMessage } from "../internal/gateway";
import { BaseEvent } from "./baseEvent";

/**
 * Represents the event dataa for a member list update.
 */
export interface MemberListEventData {
	space_id: string;
	members_total: number;
	members_online: number;
	list_ops: QuebicMemberListUpdateOp[];
}

/**
 * Represents an event from the Quebic Gateway to update the member list.
 *
 * @export
 * @class MemberListEvent
 * @extends {BaseEvent}
 */
export class MemberListEvent extends BaseEvent {
	constructor(client: Client, msg: GatewayMessage<MemberListEventData>) {
		super(client);

		this.space_id = msg.d.space_id;
		this.members_total = msg.d.members_total;
		this.members_online = msg.d.members_online;
		this.list_ops = msg.d.list_ops;
	}

	/**
	 * Represents the space_id of the member list this belongs to.
	 *
	 * @type {string}
	 * @memberof MemberListEvent
	 */
	public readonly space_id: string;
	/**
	 * The total number of members in the member list. This does *NOT* corrospond to the total number of members in the space.
	 * It's for the virtual member list only.
	 *
	 * @type {number}
	 * @memberof MemberListEvent
	 */
	public readonly members_total: number;
	/**
	 * The total number of members that are considered online in the member list.
	 *
	 * @type {number}
	 * @memberof MemberListEvent
	 */
	public readonly members_online: number;

	/**
	 * A list of operations to modify your current virtual list.
	 *
	 * @type {QuebicMemberListUpdateOp[]}
	 * @memberof MemberListEvent
	 */
	public readonly list_ops: QuebicMemberListUpdateOp[];
}