import axios from "axios";
import { QuebicApiVersion } from "../../base";
import { QuebicYoutubeMetadata } from "../../types/media";
import { Controller } from "./controller";

export class Media extends Controller {
	protected get base(): string {
		// We use the media endpoint for this controller.
		return this.client.endpoints[2] + QuebicApiVersion.V1;
	}

	/**
	 * Scrapes information about a youtube video through Quebic's Media Proxy.
	 * @param video_id
	 * @returns 
	 */
	public async youtubeMetadata(video_id: string): Promise<QuebicYoutubeMetadata> {
		return await this.fetch(axios.get(`${this.base}/youtube/${video_id}`, this.configAuth));
	}

	/**
	 * Creates a Quebic proxied image link. The url is safe to embed into the client.
	 * @param media_url 
	 * @returns 
	 */
	public createImageProxy(media_url: string): string {
		const url = new URL(media_url);
		const base = `${this.base}/image/${(url.protocol || "http:").slice(0, -1)}/${url.host}/${url.pathname.substr(1)}`;
		const params = url.searchParams.toString();

		if (params.length > 0) {
			return `${base}?${encodeURIComponent(params)}`;
		} else {
			return base;
		}
	}

	/**
	 * Creates a Quebic proxied image preview link. The url is safe to embed into the client.
	 *
	 * @param media_url
	 * @return
	 */
	public createImagePreviewProxy(media_url: string): string {
		const url = new URL(media_url);
		const base = `${this.base}/image_preview/${(url.protocol || "http:").slice(0, -1)}/${url.host}/${url.pathname.substr(1)}`;
		const params = url.searchParams.toString();

		if (params.length > 0) {
			return `${base}?${encodeURIComponent(params)}`;
		} else {
			return base;
		}
	}
}