const ISO8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

export class Json2 {
	private static extensions(key: string, value: any): any {
		// Prevent matching the regex on long strings that can't be dates.
		if (typeof value === "string" && value.length <= 40) {
			if (ISO8601.test(value)) {
				return new Date(value);
			}
		}

		return value;
	}

	/**
	 * Parses JSON with extensions to provide date and optional support.
	 *
	 * @static
	 * @template T
	 * @param {string} text
	 * @return {*}  {T}
	 * @memberof Json2
	 */
	public static parse<T>(text: string): T {
		return text ? JSON.parse(text, Json2.extensions) : null;
	}
}