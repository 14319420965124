import axios from "axios";
import { QuebicTrendingGif, QuebicGifSearchResult } from "../../types/gif";
import { Controller } from "./controller";

export class Gif extends Controller {
	protected get base(): string {
		return `${super.base}/gifs`;
	}

	/**
	 * Fetches the current trending gif categories with a preview image.
	 *
	 * @param {string} [locale="en_US"]
	 * @return {*}  {Promise<QuebicTrendingGif[]>}
	 * @memberof Gif
	 */
	public async trending(locale = "en_US"): Promise<QuebicTrendingGif[]> {
		return await this.fetch(axios.get(`${this.base}/trending`, { ...this.configAuth, params: { locale } }));
	}

	/**
	 * Search for gifs using the given search term.
	 *
	 * @param {string} term
	 * @param {string} [locale="en_US"]
	 * @return {*}  {Promise<string>}
	 * @memberof Gif
	 */
	public async search(term: string, locale = "en_US"): Promise<QuebicGifSearchResult[]> {
		return await this.fetch(axios.get(`${this.base}/search`, { ...this.configAuth, params: { term, locale } }));
	}

	/**
	 * Suggest new keywoards based on a search term.
	 *
	 * @param {string} term
	 * @param {string} [locale="en_US"]
	 * @return {*}  {Promise<string[]>}
	 * @memberof Gif
	 */
	public async suggest(term: string, locale = "en_US"): Promise<string[]> {
		return await this.fetch(axios.get(`${this.base}/suggest`, { ...this.configAuth, params: { term, locale } }));
	}
}