import { QuebicSpaceMember } from "../../types/space";
import { Client } from "../client";
import { GatewayMessage } from "../internal/gateway";
import { BaseEvent } from "./baseEvent";

/**
 * The data from the search member list event.
 */
export interface SearchMemberListData {
	space_id: string;
	search: string;
	members: QuebicSpaceMember[];
}

/**
 * Represents an event from the Quebic Gateway with member list search results.
 *
 * @export
 * @class SearchMemberListEvent
 * @extends {BaseEvent}
 */
export class SearchMemberListEvent extends BaseEvent {
	constructor(client: Client, msg: GatewayMessage<SearchMemberListData>) {
		super(client);

		this.space_id = msg.d.space_id;
		this.search = msg.d.search;
		this.members = msg.d.members;
	}

	/**
	 * Represents the space_id of the member list this belongs to.
	 *
	 * @type {string}
	 * @memberof SearchMemberListEvent
	 */
	public readonly space_id: string;
	/**
	 * The search text that these members matched on.
	 *
	 * @type {string}
	 * @memberof SearchMemberListEvent
	 */
	public readonly search: string;
	/**
	 * A list of members that were found matching the search string.
	 *
	 * @type {QuebicSpaceMember[]}
	 * @memberof SearchMemberListEvent
	 */
	public readonly members: QuebicSpaceMember[];
}