import { QuebicOpcodes } from "../../base";
import { QuebicVoiceState } from "../../types/voice";
import { Message } from "./message";

/**
 * Represents a voice state update message.
 *
 * @export
 * @class VoiceStateChange
 * @extends {Message}
 */
export class VoiceStateChange extends Message {
	/**
	 * The voice state to update.
	 *
	 * @type {QuebicVoiceState}
	 * @memberof VoiceStateChange
	 */
	public readonly state: QuebicVoiceState;

	/**
	 * Creates an instance of VoiceStateChange.
	 * @param {QuebicVoiceState} state
	 * @memberof VoiceStateChange
	 */
	constructor(state: QuebicVoiceState) {
		super(QuebicOpcodes.VoiceState);
		this.state = state;
	}

	public get payload(): string {
		return JSON.stringify({
			o: this.opcode, d: this.state
		});
	}
}