import axios from "axios";
import { QuebicChannel } from "../../types/channel";
import { QuebicDmChannels } from "../../types/directMessage";
import { Controller } from "./controller";

export class Dm extends Controller {
	protected get base(): string {
		return `${super.base}/dm`;
	}

	/**
	 * Creates or returns an existing private channel with the given recipients.
	 *
	 * @param {string[]} recipients
	 * @return {*}  {Promise<any>}
	 * @memberof Dm
	 */
	public async create(recipients: string[]): Promise<QuebicChannel> {
		return await this.fetch(axios.post(`${this.base}`, { recipients }, this.configAuth));
	}

	/**
	 * Gets a list of private 1-many channels for the user.
	 *
	 * @return {*}  {Promise<QuebicDmChannels>}
	 * @memberof Dm
	 */
	public async list(): Promise<QuebicDmChannels> {
		return await this.fetch(axios.get(`${this.base}`, this.configAuth));
	}
}