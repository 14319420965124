/**
 * Downloads a file with the given name and text content.
 * @param filename 
 * @param text 
 */
export function downloadFile(filename: string, text: string) {
	const element = document.createElement("a");

	element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
	element.setAttribute("download", filename);
	element.style.display = "none";

	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}