import axios from "axios";
import { QuebicUsername, QuebicUser } from "../../types/user";
import { QuebicUserKeys, QuebicUserDerivedKeys } from "../../types/userKeys";
import { QuebicSessionInit, QuebicUserLoginResponse } from "../../types/userLogin";
import { decryptMasterKeyTriplet, generateDeriveKeys, generateSalt, generateUserKeys } from "../../utilities/e2ee";
import { fileFormData } from "../internal/formData";
import { Controller } from "./controller";

export class User extends Controller {
	protected get base(): string {
		return `${super.base}/user`;
	}

	/**
	 * Attempts to login to the account with the given email and password.
	 * @deprecated Since 11/9/2022, migrate the account with `migrate()` and use `loginSecure(email, derive_keys)`.
	 * @param email The user's account email.
	 * @param password The user's account password.
	 * @returns A valid Quebic session token.
	 */
	public async login(email: string, password: string): Promise<QuebicSessionInit> {
		return await this.fetch(axios.post(`${this.base}/login`, { email, password }));
	}

	/**
	 * Attempts to login to the account with the given email, and user key.
	 * @param email The user's account email.
	 * @param derive_keys Generated from a call to `loginDeriveKeys("password", loginRequest("email"))`.
	 * @returns A valid Quebic session token, and encrypted master encryption key.
	 */
	public async loginSecure(email: string, derive_keys: QuebicUserDerivedKeys): Promise<QuebicSessionInit> {
		return await this.fetch(axios.post(`${this.base}/login_secure`, { email, user_key: derive_keys.user_key }));
	}

	/**
	 * Generates required keys to login to an account, and decrypt the users master key.
	 * @param password The user's account password.
	 * @param response `Generated from a call to `loginRequest("email")`.
	 * @returns The user account's derived keys.
	 */
	public async loginDeriveKeys(password: string, response: QuebicUserLoginResponse): Promise<QuebicUserDerivedKeys> {
		return await generateDeriveKeys(password, response.salt);
	}

	/**
	 * Fetches the information required to login for the given email.
	 * (This API call is heavily rate limited, cache the value)
	 *
	 * @param {string} email
	 * @return {*}  {QuebicUserLoginResponse} The user account salt, used when calling `loginDeriveKeys()`.
	 * @memberof User
	 */
	public async loginRequest(email: string): Promise<QuebicUserLoginResponse> {
		return await this.fetch(axios.post(`${this.base}/login_request`, { email }, this.config));
	}

	/**
	 * Decrypts the user's encrypted master key string.
	 * @param master_key The user account master key.
	 * @param derive_keys Generated from a call to `loginDeriveKeys("password", loginRequest("email"))`.
	 * @returns The decrypted master key as a base64 string.
	 */
	public async decryptMasterKey(master_key: string, derive_keys: QuebicUserDerivedKeys): Promise<string> {
		return await decryptMasterKeyTriplet(master_key, derive_keys.encryption_key);
	}

	/**
	 * Migrates the given account using the provided information.
	 * @param email 
	 * @param old_password 
	 * @param keys 
	 */
	public async migrateAccount(email: string, old_password: string, keys: QuebicUserKeys): Promise<void> {
		await this.fetch(axios.post(`${this.base}/migrate`, { email, old_password, keys }));
	}

	/**
	 * Verify a new user session with the given one time password code.
	 *
	 * @param {string} token
	 * @param {string} code
	 * @return {*}  {QuebicSessionInit} The user session token. You must pass this to the client in order to connect to the gateway.
	 * @memberof User
	 */
	public async verifyOtp(token: string, code: string): Promise<QuebicSessionInit> {
		return await this.fetch(axios.post(`${this.base}/verify_otp`, { code }, { ...this.config, headers: { "Authorization": `Bearer ${token}` } }));
	}

	/**
	 * Creates a new authentication token using the provided refresh token.
	 * It is used for `await client.login(token)` calls to authenticate the user.
	 * (This token expires after a short amount of time)
	 *
	 * @param {string} token
	 * @return {*}  {Promise<QuebicSessionInit>}
	 * @memberof User
	 */
	public async refresh(token: string): Promise<QuebicSessionInit> {
		return await this.fetch(axios.post(`${this.base}/refresh`, {}, { ...this.config, headers: { "Authorization": `Bearer ${token}` } }));
	}

	/**
	 * Checks whether or not a username has been claimed.
	 *
	 * @param {string} username
	 * @return {*}  {Promise<QuebicUsername>}
	 * @memberof User
	 */
	public async checkUsername(username: string): Promise<QuebicUsername> {
		return await this.fetch(axios.post(`${this.base}/check_username`, { username }, this.config));
	}


	/**
	 * Create a new user account with the provided registration.
	 * This will return a new user session, which will remain unverified until the user attempts to do so.
	 * (Spaces can limit the verification level of accounts)
	 * @param email The user's email.
	 * @param username The user's username.
	 * @param token The user's Cloudflare turnstile challenge token.
	 * @param keys The user's user keys created using `createRegisterKeys(password)`.
	 * @returns 
	 */
	public async register(email: string, username: string, token: string, keys: QuebicUserKeys): Promise<QuebicSessionInit> {
		return await this.fetch(axios.post(`${this.base}/register`, { email, username, token, keys }, this.config));
	}

	/**
	 * Generates required keys to login to an account after registering, and decrypt the users master key.
	 * @param password The user's account password.
	 * @param user_keys Generated from a call to `createRegisterKeys(password)`.
	 * @returns 
	 */
	public async registerDeriveKeys(password: string, user_keys: QuebicUserKeys): Promise<QuebicUserDerivedKeys> {
		return await generateDeriveKeys(password, await generateSalt(user_keys.client_random));
	}

	/**
	 * Creates a new set of user keys used for account registration.
	 * @param password The user's password.
	 * @returns 
	 */
	public async createRegisterKeys(password: string): Promise<QuebicUserKeys> {
		return await generateUserKeys(password);
	}

	/**
	 * Resends a new email containing a verification code to activate
	 * your new account. (Only 3 per 30 minutes are allowed).
	 *
	 * @param {string} email
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async resendVerifyCode(email: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/resend_verify_code`, { email }, this.config));
	}

	/**
	 * Resends a new email containing a verification code to activate
	 * your currently logged in account. (Only 3 per 30 minutes are allowed).
	 */
	public async resendVerifyCodeAuthenticated(): Promise<void> {
		await this.fetch(axios.post(`${this.base}/resend_verify_code_authenticated`, {}, this.configAuth));
	}

	/**
	 * Verify an existing user accounts email with the provided verification code.
	 *
	 * @param {string} email
	 * @param {string} verification_code
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async verifyEmail(email: string, verification_code: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/verify_email`, { email, verification_code }, this.config));
	}

	/**
	 * Attempt to reset the password for an account by sending an email with reset instructions.
	 *
	 * @param {string} email
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async forgotPassword(email: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/forgot_password`, { email }, this.config));
	}

	/**
	 * Attempt to reset an account password using the reset password code.
	 *
	 * @param {string} email
	 * @param {string} reset_code
	 * @param {string} password
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async resetPassword(email: string, reset_code: string, password: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/reset_password`, { email, reset_code, password }, this.config));
	}

	/**
	 * Get information about the specified user.
	 * When no user_id is provided, returns information about the current session.
	 *
	 * @param {string} user_id
	 * @return {*}  {Promise<QuebicUser>}
	 * @memberof User
	 */
	public async get(user_id?: string): Promise<QuebicUser> {
		return await this.fetch(axios.get(user_id ? `${this.base}/${user_id}` : `${this.base}`, this.configAuth));
	}

	/**
	 * Upload an avatar for a user.
	 * When no user_id is provided, it applies to the current session.
	 *
	 * @param {(string | Blob)} avatar
	 * @param {string} [user_id]
	 * @param {(test: any) => void} [onprogress]
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async uploadAvatar(avatar: string | Blob, user_id?: string, onprogress?: (event: ProgressEvent) => void): Promise<QuebicUser> {
		const data = fileFormData(avatar);
		if (process.env.IS_WEBPACK) {
			return await this.fetch(axios.post(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, data, { ...this.configAuth, onUploadProgress: onprogress }));
		} else {
			return await this.fetch(axios.post(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, data.getBuffer(), { ...this.configAuth, onUploadProgress: onprogress, headers: { ...this.configAuth.headers, ...data.getHeaders() } }));
		}
	}

	/**
	 * Delete an existing avatar image.
	 * When no user_id is provided, it applies to the current session.
	 * 
	 * @param {string} [user_id]
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async deleteAvatar(user_id?: string): Promise<void> {
		await this.fetch(axios.delete(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, this.configAuth));
	}
}