import axios from "axios";
import { Controller } from "./controller";

export class Pins extends Controller {
	protected get base(): string {
		return `${super.base}/channel`;
	}

	/**
	 * Pins a message to the specified channel.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @return {*}  {Promise<void>}
	 * @memberof Pins
	 */
	public async pin(channel_id: string, message_id: string): Promise<void> {
		await this.fetch(axios.put(`${this.base}/${channel_id}/pins/${message_id}`, {}, this.configAuth));
	}

	/**
	 * Unpins a message from a channel.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @return {*}  {Promise<void>}
	 * @memberof Pins
	 */
	public async unpin(channel_id: string, message_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${channel_id}/pins/${message_id}`, this.configAuth));
	}

	/**
	 * Fetches a list of pinned messages from a channel.
	 *
	 * @param {string} channel_id
	 * @return {*}  {Promise<any[]>}
	 * @memberof Pins
	 */
	public async list(channel_id: string): Promise<any[]> {
		return await this.fetch(axios.get(`${this.base}/${channel_id}/pins`, this.configAuth));
	}
}