import { QuebicOpcodes } from "../../base";
import { Message } from "./message";

/**
 * Represents a search member list command message.
 *
 * @export
 * @class SearchMemberList
 * @extends {Message}
 */
export class SearchMemberList extends Message {
	/**
	 * The space_id to search.
	 *
	 * @type {string}
	 * @memberof SearchMemberList
	 */
	public readonly space_id: string;
	/**
	 * The search text to find.
	 *
	 * @type {string}
	 * @memberof SearchMemberList
	 */
	public readonly search: string;

	/**
	 * Creates an instance of SearchMemberList.
	 * @param {string} space_id
	 * @param {string} search
	 * @memberof SearchMemberList
	 */
	constructor(space_id: string, search: string) {
		super(QuebicOpcodes.SearchMemberList);
		this.space_id = space_id;
		this.search = search;
	}

	public get payload(): string {
		return JSON.stringify({
			o: this.opcode, d: {
				space_id: this.space_id,
				search: this.search,
			}
		});
	}
}